import React, { useState, useEffect } from 'react';
import sanityClient from '../sanityClient';
import Footer from '../components/layout/footer/Footer';
import moment from 'moment';
import BlockContent from '@sanity/block-content-to-react';
import NavBar from '../components/layout/navbar/NavBar';
import {Link , Redirect} from 'react-router-dom'

const TheWeeklyPump = ({user , setUser}) => {

    const [pumpPost, setpumpPost] = useState(null);
    console.log(user);
    useEffect(() => {
      sanityClient
        .fetch(`*[_type == "weeklyPump"]{
          title,
          body,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          publishedAt,
          url
        }`)
        .then((data) => setpumpPost(data))
        .catch(console.error)
        console.log(pumpPost)
  
    },[])
  
    let sessionEmail;
    if (typeof window !== 'undefined') {
       sessionEmail = sessionStorage.getItem('email');
  
  }
    if (!user && !sessionEmail) {
      return (<Redirect to="login" />)
    } 
    return (
      <div>
        <div className=".container.is-widescreen">
        <section className="hero is-danger is-halfheight">
          <NavBar/>
          <div className="hero-body">
            <div className="mt-6">
              <p className="title is-1 fadeIn mt-6">
                The Weekly Pump
              </p>
              <p className="subtitle is-3 fadeIn">
                The Only Training Blog You Need
              </p>
            </div>
          </div>
        </section>
  
          <section class="articles" style={{backgroundColor:'#f6f6f6', paddingTop:'50px'}}>
              <div class="column is-8 is-offset-2">
          { pumpPost && pumpPost.map((post) =>{
              return (
                  <div class="card article mb-6 mt-6">
                      <div class="card-content">
                          <div class="media">
                              <div class="media-content has-text-centered">
                                  <p class="title article-title">{post.title}</p>
                                  <div class="tags has-addons level-item">
                                      <span class="tag is-rounded is-info">Published On {moment(post.publishedAt).format("MMM Do YYYY")}</span>
                                  </div>
                                  <img src={post.mainImage.asset.url} class="author-image" alt="Placeholder image"/>
                              </div>
                          </div>
                          <div class="content article-body" style={{maxHeight:'250px',overflow:'hidden'}}>
                              <BlockContent blocks={post.body} />
                          </div>
                          <Link to={`/the-weekly-pump/${post.slug.current}`}><button className="button is-primary">Read More ...</button></Link>
                      </div>
                  </div>
              )
                  
                   })}
                </div>
  
          </section>
  
  
        <Footer/>
        </div>
      </div>
    )
  }

  export default TheWeeklyPump;
  