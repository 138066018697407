import {Fragment} from 'react'



const InfoSections = ({infoSections}) => {
    return (
        <Fragment>
          {infoSections?.map((item , index) => { 
            if(index / 1 != 1) {
              return (
                <section className="section">
                <div class="columns is-gapless">
                  <div class="column center">
                    <p className="title is-3 mb-6">{item.title}</p>
                    <p className="subtitle is-4 mb-6 p-4">{item.body}</p>
                    <a href="mailto:jashan.khela@hotmail.com"class="button is-primary is-large mb-6">Contact Now</a>
                  </div>
                  <div class="column">
                    <img src={item.image.asset.url} alt="" style={{maxHeight: '500px',borderRadius: '20px'}} />
                  </div>
                </div>
              </section>
              )
            } else {
              return (
                <section className="section" style={{backgroundColor: 'black'}}>
                <div class="columns is-gapless">
                <div class="column">
                    <img src={item.image.asset.url} alt="" style={{maxHeight: '500px',borderRadius: '20px'}} />
                  </div>
                  <div class="column center">
                    <p className="title is-3 mb-6 has-text-white">{item.title}</p>
                    <p className="subtitle is-4 mb-6 p-4 has-text-white">{item.body}</p>
                    <a href="mailto:jashan.khela@hotmail.com"class="button is-primary is-large mb-6">Contact Now</a>
                  </div>
                </div>
              </section>
              )
            }

          })}
        </Fragment>
    )
}
export default InfoSections;