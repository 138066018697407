import {Link} from 'react-router-dom';
import AppContext from '../../../AppContext';
import { useContext } from 'react';
import { useState } from 'react';

const NavBar = ({value}) => {
  const myContext = useContext(AppContext);
  console.log(myContext);
  const [isActive, setisActive] = useState(false);
    return (
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img className='logo' src="/logo/logo.svg" />
          </a>
          <a
            onClick={() => {
              setisActive(!isActive);
            }}
            role="button"
            className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample">
              
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div
          id="navbarBasicExample"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
        >
          <div className="navbar-end" style={{paddingTop:'60px'}}>
            <Link to="/about">
            <a className="navbar-item has-text-white">
              About Me
            </a>
            </Link>
            <Link to="/howitworks">
            <a className="navbar-item has-text-white">
              How Does It Work?
            </a>
            </Link>
            <Link to="/the-weekly-pump">
            <a className="navbar-item has-text-white">
              Follow My Program
            </a>
            </Link>
            <Link to="/nutrition">
            <a className="navbar-item has-text-white">
              Nutrition + Tips
            </a>
            </Link>
            <a href="https://mealprepforyou.ca/">
                  <div className="navbar-item has-text-white">
                    <div className="buttons">
                      <a className="button is-primary">
                        <strong>MP4U</strong>
                      </a>
                    </div>
                  </div>
            </a>
            {myContext.user === null ? (<Link to="/login">
                  <div className="navbar-item has-text-white">
                    <div className="buttons">
                      <a className="button is-primary">
                        <strong>Login</strong>
                      </a>
                    </div>
                  </div>
            </Link>): (<a onClick={myContext.logout}>
                  <div className="navbar-item has-text-white">
                    <div className="buttons">
                      <a className="button is-primary">
                        <strong>Logout</strong>
                      </a>
                    </div>
                  </div>
            </a>)}


            <div className="navbar-item has-text-white">
                <a className="button is-dark">
                  <strong><i class="far fa-envelope"></i></strong>
                </a>
            </div>
          </div>
        </div>
      </nav>
    )
}

export default NavBar;
