import React, { useState, useEffect } from 'react';
import sanityClient from '../sanityClient';
import Footer from '../components/layout/footer/Footer';
import InfoSections from '../components/pages/home/InfoSections';
import Testimonials from '../components/pages/home/Testimonials';
import NavBar from '../components/layout/navbar/NavBar';

const Home = () => {
    const [testimonials, setTestimonials] = useState(null);

    useEffect(() => {
      sanityClient
        .fetch(`*[_type == "testimonial"]{
          title,
          name,
          body,
          image{
            asset->{
              _id,
              url
            },
            alt
          },
          publishedAt,
  
        }`)
        .then((data) => setTestimonials(data))
        .catch(console.error)
    },[])
  
    return (

        <div className=".container.is-widescreen">
        <section className="hero is-primary is-fullheight">
        <NavBar/>
          <div className="hero-body">
            <div className="">
              <p className="title is-1 fadeIn mt-6">
                Morgan Kyle
              </p>
              <p className="subtitle is-3 fadeIn">
                Not Just Another Workout Community, <br></br> But A Lifestyle
              </p>
              <button className="button is-primary is-large">
                Get Started 
              <span className="icon">
                <i className="fas fa-dumbbell"></i>
              </span>
              </button>
            </div>
          </div>
        </section>
        <InfoSections/>
        <Testimonials testimonials={testimonials}/>
        <Footer />
      </div>
    )
}

export default Home;