import { useState , useEffect , React } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const Login = ({history,setUser}) => {

  let params = useParams();
    console.log(params)

    const { register, handleSubmit, errors } = useForm(); // initialize the hook
    const [loginError, setLoginError] = useState(null);
    const onSubmit = (data) => {
      if (!errors.email && !errors.password) {
        axios.post('api/login', {
          email: data.email,
          password: data.password,
        })
          .then(function (response) {
              console.log(response)
            if (response.data.errors) {
              setLoginError(response.data.errors);
              console.log('this hits',loginError)
            } else {
            setUser(response.data);
            history.push('/the-weekly-pump')
            }
          })
      } else {
        console.log(errors.length)
      }
  
    };


    return (
        <div className=".container.is-widescreen">
        <section className="hero is-dark is-fullheight login">
          <div class="hero-body">
            <div class="container has-text-centered">
              <div class="column is-4 is-offset-4">
                <h3 class="title has-text-primary">Welcome Back !</h3>
                <h3 class="subtitle has-text-primary">Login Here</h3>
                <hr class="login-hr is-primary" />
                <p class="subtitle has-text-white">Please login to proceed.</p>
                {loginError ? (<article class="message is-danger">
                  <div class="message-header">
                    <p>Invalid Credentials</p>
                    <button class="delete" aria-label="delete"></button>
                  </div>
                </article>) : null}
                <div class="box">
                  <figure class="avatar">
                    <img src="/logo/logo.svg" />
                  </figure>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div class="field">
                      <div class="control">
                        <input name="email" ref={register({ required: true })} class="input is-large" type="email" placeholder="Your Email" autoFocus="" />
                        {errors.email && 'Please Enter Email'}
                      </div>
                    </div>

                    <div class="field">
                      <div class="control">
                        <input name="password" ref={register({ required: true })} class="input is-large" type="password" placeholder="Your Password" />
                      </div>
                    </div>

                    <button class="button is-block is-primary is-large is-fullwidth">Login <i class="fa fa-sign-in" aria-hidden="true"></i></button>
                  </form>
                </div>
                <p class="has-text-grey">
                  <a href="/register">Sign Up</a> &nbsp;·&nbsp;
                        <a href="../">Forgot Password</a> &nbsp;·&nbsp;
                        <a href="../">Need Help?</a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  export default Login;
  