import React, { Fragment, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
//import pages
import Home from './pages/Home';
import About from './pages/About';
import HowItWorks from './pages/HowItWorks';
import Nutrition from './pages/Nutrition';
import NutritionBlogPost from './pages/NutritionBlogPost'
import TheWeeklyPumpPost from "./pages/TheWeeklyPumpPost";
import TheWeeklyPump from "./pages/TheWeeklyPump";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AppContext from './AppContext';



function App() {

  const [user, setUser] = useState(null);

  const setUserState = (value) => {
    setUser(value);
    sessionStorage.setItem('email',value.email);
    console.log(user);
  } 
  const logout = (value) => {
    setUser(null);
    sessionStorage.removeItem('email');
  } 


  let store = {
    user: user,
    setUserState,
    logout,
   }
  
  return (
    <Fragment>
    <AppContext.Provider value={store}>
    <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route
        path='/login'
        render={(props) => (
        <Login {...props} user={user} setUser={setUserState} />
        )}
      />  
      <Route  path="/register" component={Register} />
      <Route  path="/about" component={About} />
      <Route  path="/howitworks" component={HowItWorks} />
      <Route  exact path="/nutrition" component={Nutrition} />
      <Route  path="/nutrition/:slug" component={NutritionBlogPost} />
      <Route
        exact
        path='/the-weekly-pump'
        render={(props) => (
        <TheWeeklyPump {...props} user={user} setUser={setUserState} />
        )}
      />
      <Route
        
        path='/the-weekly-pump/:slug'
        render={(props) => (
        <TheWeeklyPumpPost {...props} user={user} setUser={setUserState} />
        )}
      />

    </Switch>
    </Router>
    </AppContext.Provider>

  </Fragment>
  );
}

export default App;
