import {Link} from 'react-router-dom';
import { useState } from "react";
import axios from "axios";
const Footer = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://formspree.io/f/myybayvz",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };
    return (
        <footer class="footer">
          <div class="columns">
            <div class="column has-text-left">
              <img className='logo' src="/logo/logo.svg" />
            </div>
            <div className="column">
              <p className="title is-3 has-text-white">
                Quick Links
              </p>
              <Link to="/">
                <a>
                <div className="columns is-mobile">
                <div className="column is-half">
                  <p className="bd-notification is-danger">Home</p>
                </div>
                <div className="column">
                  <p className="bd-notification is-danger"><i className="fas fa-home"></i></p>
                </div>
              </div>
                </a>
             
              </Link>
              <Link to="/about">
                <a>
              <div className="columns is-mobile">
                <div className="column is-half">
                  <p className="bd-notification is-danger">About Morgan Kyle</p>
                </div>
                <div className="column">
                  <p className="bd-notification is-danger"><i className="fas fa-user"></i></p>
                </div>
              </div>
              </a>
              </Link>

              <Link to="/howitworks">
              <a>
              <div className="columns is-mobile">
                <div className="column is-half">
                  <p className="bd-notification is-danger">How It Works?</p>
                </div>
                <div className="column">
                  <p className="bd-notification is-danger"><i class="fas fa-info"></i></p>
                </div>
              </div>
              </a>
              </Link>

              <Link to="/the-weekly-pump">
              <a>
              <div className="columns is-mobile">
                <div className="column is-half">
                  <p className="bd-notification is-danger">The  Weekly Pump</p>
                </div>
                <div className="column">
                  <p className="bd-notification is-danger"><i className="fas fa-dumbbell"></i></p>
                </div>
              </div>
              </a>
              </Link>
              <Link to="/nutrition">
              <a>
              <div className="columns is-mobile">
                <div className="column is-half">
                  <p className="bd-notification is-danger">Nutrition</p>
                </div>
                <div className="column">
                  <p className="bd-notification is-danger"><i class="fa fa-cutlery" ></i>
                  </p>
                </div>
              </div>
              </a>
              </Link>
              <a href="https://mealprepforyou.ca/">
              
              <div className="columns is-mobile">
                <div className="column is-half">
                  <p className="bd-notification is-danger">MP4U</p>
                </div>
                <div className="column">
                  <p className="bd-notification is-danger"><i class="fas fa-shipping-fast"></i></p>
                </div>
              </div>
              </a>
            </div>
            <div className="column">
              <div class="containers">
                <h2 class="title is-2 is-capitalized has-text-white">Contact Us</h2>
                <form onSubmit={handleOnSubmit}>
                  <div class="field">
                    <label for="name" class="label is-size-4 has-text-weight-light"></label>
                    <div class="control has-icons-left">
                      <input type="text" name="name" id="name" class="input" placeholder="Name" autofocu />
                      <span class="icon is-left">
                        <i class="fa fa-user"></i>
                      </span>
                    </div>
                  </div>
                  <div class="field">
                    <label for="email" class="label is-size-4 has-text-weight-light"></label>
                    <div class="control has-icons-left">
                      <input type="email" name="email" id="email" class="input" placeholder="Email" />
                      <span class="icon is-left">
                        <i class="fa fa-envelope"></i>
                      </span>
                    </div>
                  </div>
                  <div class="field">
                    <label for="message" class="label is-size-4 has-text-weight-light"></label>
                    <textarea name="message" id="message" rows="5" class="textarea is-medium" placeholder="Message"></textarea>
                  </div>
                  <button type="submit" class="button is-primary is-size-5">{serverState.submitting ? 'Submitting' : 'Submit'}</button>
                </form>
              </div>
            </div>
          </div>
          <div class="content has-text-centered">
            <p>
              Developed and Maintained By <strong style={{ color: "white" }}>@JashanKhela</strong>
            </p>
          </div>
        </footer>
    )
}

export default Footer;