import React, { useState, useEffect } from 'react';
import sanityClient from '../sanityClient';
import Footer from '../components/layout/footer/Footer';
import InfoSections from '../components/pages/about/InfoSections';
import NavBar from '../components/layout/navbar/NavBar';

const About = () => {
    const [infoSections, setInfoSections] = useState(null);
  
    useEffect(() => {
      sanityClient
        .fetch(`*[_type == "infoSections"]{
          title,
          body,
          image{
            asset->{
              _id,
              url
            },
            alt
          },
          publishedAt,
        }`)
        .then((data) => setInfoSections(data))
        .catch(console.error)
    },[])
    return (
      <div>
        <div className=".container.is-widescreen">
          <section className="hero is-danger is-fullheight">
            <NavBar/>
            <div className="hero-body">
              <div className="">
                <p className="title is-1 fadeIn mt-6">
                  Who Is Morgan Kyle?
                </p>
                <p className="subtitle is-3 fadeIn">
                  Not Just Another Trainer
                </p>
              </div>
            </div>
          </section>
        <InfoSections infoSections={infoSections}/>
        <Footer/>
        </div>
      </div>
    )
  }

  export default About
  