const Testimonials = ({testimonials}) => {
    return(
        <section className="section">
        <div className="column center mb-6">
          <h1 className="title is-1">What Do My Clients Have To Say?</h1>
        </div>
        <div className="column center">
          <div class="columns">
            {testimonials && testimonials.map((testimonial,index) => {
              return (
                <div key={index} class="column">
                <div class="box has-background-white has-text-grey-dark" style={{minHeight: '250px'}}>
                  <p>
                    <span class="font-bold has-text-primary is-size-5">
                      “
                    </span>
                    {testimonial.body[0].children[0].text}
                  <span class="font-bold has-text-primary is-size-5">
                      ”
                  </span>
                  </p>
                  <div class="is-flex is-align-items-center is-justify-content-start mt-4">
                    <a href="#" class="image is-48x48 mb-4">
                      <img alt="testimonial-picture" src={testimonial.image.asset.url} class="is-rounded" />
                    </a>
                    <div class="is-flex is-flex-direction-column ml-2 is-align-content-space-between">
                      <span class="font-semibold has-text-weight-bold">
                        {testimonial.name}
                      </span>
                      <span class="is-size-7 is-flex is-align-items-center">
                        {testimonial.title}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              )
            })}
          </div>
        </div>
      </section>
    )
}

export default Testimonials;