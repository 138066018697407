import {Fragment} from 'react'
import {Link} from 'react-router-dom';


const InfoSections = () => {
    return (
        <Fragment>
        <section className="section">
            <div className="column center mb-6">
                <h1 className="title is-1">Why Train With Morgan?</h1>
            </div>
            <div className="column center">
                <div class="columns">
                    <div class="column">
                        <img className='info_img mb-4' src="/images/healthy.svg" alt="" />
                        <p className="title is-3 mb-5">The Weekly Pump</p>
                        <p className="subtitle is-6">Follow a personal workout regimate created by your coach weekly. Say goodbye to boring, repetitve workouts and get ready to be tested every week</p>
                        <Link to="the-weekly-pump">
                        <button class="button is-primary">The Weekly Pump</button>
                        </Link>

                    </div>
                    <div class="column">
                        <img className='info_img mb-4' src="/images/lifestlye.svg" alt="" style={{ borderRadius: '20px' }} />
                        <p className="title is-3 mb-5">A Healthy Lifestyle Choice</p>
                        <p className="subtitle is-6">Whether you are on the go or work a desk job, Morgan will have the right diet and training program to help you achieve your fitness goals</p>
                        <Link to="/nutrition">
                        <button class="button is-primary">Lifestyle Coaching</button>
                        </Link>
                    </div>
                    <div class="column">
                        <img className='info_img mb-4' src="/images/track.svg" alt="" />
                        <p className="title is-3 mb-5">Let Us Do The Tracking</p>
                        <p className="subtitle is-6">With our collaboration with <strong>MP4U</strong> our plans show exactly what meals you can order to stay on top of your lifestyle. We even count the macros for you!</p>
                        <a href="https://mealprepforyou.ca/order/"><button class="button is-primary">See MP4U Menu</button></a>
                    </div>
                </div>
            </div>
        </section>
        <section className="section" style={{ backgroundColor: 'black' }}>
            <div class="columns is-gapless">
                <div class="column center">
                    <p className="title is-3 has-text-white mb-6">Collaboration For Innovation</p>
                    <p className="subtitle is-4 has-text-white mb-6 p-4">Morgan has teamed up with a local buisness to ensure that his clients are getting the best Nutrition to reach their fitness goals. <strong className="has-text-primary	">MP4U</strong> provides nutrious meals that are full of protein and full of taste! They have everything from breakfast to donuts and who said dieting was hard? Check The Weekly Pump and Nutrition blogs for meals in weekly updates.</p>
                    <a href="https://mealprepforyou.ca/order/" class="button is-primary is-large mb-6">Order Now</a>

                </div>
                <div class="column">
                    <img style={{borderRadius:'25px'}} src="https://images.unsplash.com/photo-1490645935967-10de6ba17061?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTd8fGhlYWx0aHklMjBtZWFsfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60" alt="" />
                </div>
            </div>
        </section>
        </Fragment>
    )
}
export default InfoSections;