import React, { useState, useEffect } from 'react';
import sanityClient from '../sanityClient';
import Footer from '../components/layout/footer/Footer';
import InfoSections from '../components/pages/about/InfoSections';
import NavBar from '../components/layout/navbar/NavBar';
import { useParams , Redirect } from "react-router-dom";
import imageUrlBuilder from '@sanity/image-url';
import {Link } from 'react-router-dom';
import moment from 'moment';
import BlockContent from '@sanity/block-content-to-react';

const TheWeeklyPumpPost = ({user , setUser}) => {
    const [imageUrl, setImageUrl] = useState('');
    const [post, setPost] = useState(null);



    let { slug } = useParams();
    console.log(slug)
    useEffect( () => {
        async function fetchData() {
            const query = encodeURIComponent(`*[ _type == "weeklyPump" && slug.current == "${slug}" ]`);
            const url = `https://gymkoedk.api.sanity.io/v1/data/query/production?query=${query}`;
          
            const result = await fetch(url).then(res => res.json());
            const post = result.result[0];
            await setPost(post);
            console.log(post);
            const imgBuilder = imageUrlBuilder({
                projectId: 'gymkoedk',
                dataset: 'production',
              });
              setImageUrl(imgBuilder.image(post.mainImage));

        }
        fetchData();

    },[slug])

    let sessionEmail;
    if (typeof window !== 'undefined') {
       sessionEmail = sessionStorage.getItem('email');
  
  }
    if (!user && !sessionEmail) {
      return (<Redirect to="/login" />)
    } 
    return (
        <div className=".container.is-widescreen">
        <section className="hero is-primary is-halfheight">
            <NavBar/>
        </section>
        <section className="section" style={{backgroundColor:'#f6f6f6'}}>
          <div className="columns is-gapless">
            <div className="column">
              <a href="/the-weekly-pump"><a style={{color:'black',fontSize:'16px',marginBottom:'10px'}}><i class="fas fa-chevron-left" style={{marginRight:'15px'}}></i>The Weekly Pump</a></a>         
              <div class="card article mb-6 mt-6">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content has-text-centered">
                                <p class="title article-title">The Weekly Pump - {post?.title}</p>
                                <div class="tags has-addons level-item">
                                    <span class="tag is-rounded is-info">Published On {moment(post?.publishedAt).format("MMM Do YYYY")}</span>
                                </div>
                                {imageUrl && <figure style={{display:'flex',justifyContent:'center'}} className="image"><img style={{maxHeight:'500px',maxWidth:'500px'}}className="is-rounded" src={imageUrl} alt={post?.title} /></figure>}
                            </div>
                        </div>
                        <div class="content article-body">
                            <BlockContent blocks={post?.body} />
                        </div>
                        <div className="has-text-centered">
                        <a href={post?.url}>  
                        <a className="button is-primary is-large">
                            Workout Video
                            <span className="icon">
                              <i class="fab fa-youtube"></i>
                            </span>
                        </a>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
      <Footer/>
      </div>
    )
  }

  export default TheWeeklyPumpPost
  