import React from 'react';
import Footer from '../components/layout/footer/Footer';
import NavBar from '../components/layout/navbar/NavBar';

const HowItWorks = () => {
    return (
        <div className=".container.is-widescreen">
        <section className="hero is-danger is-fullheight">
            <NavBar/>
          <div className="hero-body">
            <div className="">
              <p className="title is-1 fadeIn mt-6">
                How Is Morgan Kyle Going To Help ?
              </p>
              <p className="subtitle is-3 fadeIn">
                Follow Along and Take On The Challenge
              </p>
            </div>
          </div>
        </section>
        <section className="section" style={{backgroundColor:'#f6f6f6'}}>
          <div class="columns ">
            <div class="column center">
              <p className="title is-3 mb-6"> 1) Subscribe</p>
              <p className="subtitle is-4 mb-6 p-4">If there is one thing about me you should know, it is that I like to plan ahead! This is why I encourage visitors to come early in the week so they can plan out their weeks with me. I post meals and workouts every Sunday so stay ready! Head over to the <strong className="has-text-primary">The Weekly Pump</strong> to subscribe to my limited content and get the full experience.</p>
            </div>
            <div class="column">
              <img src="images/browse.svg" alt="" style={{maxHeight: '250px',borderRadius: '20px'}} />
            </div>
          </div>
        </section>
        <section className="section">
          <div class="columns is-gapless">
            <div class="column center">
              <img src="images/step2.svg" alt="" style={{maxHeight: '250px',borderRadius: '20px'}} />
            </div>
            <div class="column">
              <p className="title is-3 mb-6"> 2) PLAN YOUR WORKOUT</p>
              <p className="subtitle is-4 mb-6 p-4">Okay now that you know that I like to plan ahead lets talk training. Visit <strong className="has-text-primary">The Weekly Pump</strong> page to get the same workouts that I will be doing this week. From training splits , to rep ranges and more, everything you need to know to plan your weeks worth of training out! There is also a work along video!</p>
            </div>
          </div>
        </section>
        <section className="section" style={{backgroundColor:'#f6f6f6'}}>
          <div class="columns is-gapless">
            <div class="column center">
              <p className="title is-3 mb-6"> 3) LETS TALK DIET</p>
              <p className="subtitle is-4 mb-6 p-4">I know we have all heard the cliche, abs are made in the kitchen. Well guess what, that is still true however that does not mean you cannot still enjoy some of your favorite meals! Head over to the <strong className="has-text-primary">Nutrition Blog</strong> to get on the same meal plan as me! Whats even better is since partnering with MP4U, I have listed all the meals I will be having in a week!</p>
            </div>
            <div class="column">
              <img src="images/step3.svg" alt="" style={{maxHeight: '250px',borderRadius: '20px'}} />
            </div>
          </div>
        </section>
        <section className="section">
          <div class="columns is-gapless">
            <div class="column center">
              <img src="images/step4.svg" alt="" style={{maxHeight: '250px',borderRadius: '20px'}} />
            </div>
            <div class="column">
              <p className="title is-3 mb-6"> 4) LET'S GET TO WORK</p>
              <p className="subtitle is-4 mb-6 p-4">We have a long week ahead of us so lets get ourselves mentally prepared. Having a healthy lifestyle is all about being right both mentally and physically, so it is important to remember that! Don't forget to head over to <strong className="has-text-primary">MP4U</strong> to order your meals on Sunday or Wednesday for the Week. Have a great week and lets get better everyday! </p>
            </div>
          </div>
        </section>
      <Footer/>
      </div>
    )
  }

  export default HowItWorks;
  