import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useRef, useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51HwGSlKlrHRgygPFcdu4rH45Ntl68ETp9W2WsGCSqIjroofINXHBvzl2FxE7ATYzWOIr5vUZnmgZVMBJl7NtL7uL00o8P4x7Gt')


const Register = ({history,setUser}) => {

    const [registerError, setRegisterError] = useState(null);

    const { register, handleSubmit, errors, watch } = useForm(); // initialize the hook
    const password = useRef({});
    password.current = watch("password", "");


    const onSubmit = async (data) => {
        if (!errors.password_repeat && !errors.password) {
            axios.post('api/register', {
                email: data.email,
                password: data.password,
                isSubscribed: true
            })
            .then(function (response) {
                    if(response.data !== 'Registered'){
                        setRegisterError("Registeration Failed Please Try Again")
                    } else {
                        console.log("this hits");
                        setRegisterError(null);
                            let sessionId;
                            axios.post('api/checkout')
                            .then(function  (response) {
                              console.log(response.data.sessionId); 
                              sessionId = response.data.sessionId;
                              console.log(sessionId)
                            }).then(async function(response) {
                                const stripe = await stripePromise;
                                const { error } = stripe.redirectToCheckout({
                                    sessionId
                                })
                              })
                        
                    }
                })

        } else {
            console.log(errors.length)
        }

    };


    return (
        <div className=".container.is-widescreen">
        <section className="hero is-dark is-fullheight login">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="column is-4 is-offset-4">
                        <h3 className="title has-text-primary">Join Today!</h3>
                        <h3 className="subtitle has-text-primary">Register</h3>
                        <hr className="login-hr is-primary" />
                        <p className="subtitle has-text-white">Please fill in the form to proceed.</p>
                        {registerError ? (<article class="message is-danger">
                            <div class="message-header">
                                <p>{registerError}</p>
                                <button class="delete" aria-label="delete"></button>
                            </div>
                        </article>) : null}
                        <div className="box">
                            <figure className="avatar">
                                <img src="/logo/logo.svg" />
                            </figure>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="field">
                                    <label>Email</label>
                                    <div className="control">
                                        <input name="email" ref={register({ required: true })} className="input is-large" type="email" placeholder="Your Email" autoFocus="" />
                                        {errors.email && 'Please Enter Email'}
                                    </div>
                                </div>
                                <div className="field">
                                    <label>Password</label>
                                    <div className="control">
                                        <input name="password" ref={register({
                                            required: "You must specify a password",
                                            minLength: {
                                                value: 8,
                                                message: "Password must have at least 8 characters"
                                            }
                                        })} className="input is-large" type="password" placeholder="Your Password" />
                                    </div>
                                </div>
                                {errors.password && <p>{errors.password.message}</p>}
                                <div className="field">
                                    <label>Confirm Password</label>
                                    <div className="control">
                                        <input name="password_repeat" ref={register({
                                            validate: value =>
                                                value === password.current || "The passwords do not match"
                                        })} className="input is-large" type="password" placeholder="Confirm Password" />
                                    </div>
                                </div>
                                {errors.password_repeat && <p>{errors.password_repeat.message}</p>}
                                <div className="field">
                                    <label>Select A Plan</label>
                                    <div className="control">
                                        <div className="select is-large">
                                            <select name="price" ref={register}>
                                                <option value="twenty">$19.99/monthly</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <button role="link" className="button is-primary is-small ">Continue To Checkout<i className="fa fa-sign-in" aria-hidden="true"></i></button>
                            </form>
                        </div>
                        <p className="has-text-grey">
                            <a href="../">Sign Up</a> &nbsp;·&nbsp;
                <a href="../">Forgot Password</a> &nbsp;·&nbsp;
                <a href="../">Need Help?</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
    )
  }

  export default Register;
  